'use client';

import React from 'react';

import LoginEmailForm from '@/components/TCA/features/LoginEmailForm/LoginEmailForm';
import HeadingGeneric from '@/components/ui/headings/HeadingGeneric';

export default function LoginPageClientComponent() {
  return (
    <>
      <HeadingGeneric experience="login" hasLogo />
      <LoginEmailForm />
    </>
  );
}
