'use client';

import { ZENDESK_URL } from '@/shared/constants';
import React from 'react';
import { useTranslations } from 'use-intl';

import BottomLink from '@/components/ui/BottomLink';

const SupportLink = () => {
  const t = useTranslations('form');

  return (
    <BottomLink
      linkUrl={ZENDESK_URL}
      linkCopy={t('support.switch-label')}
      spanCopy={t('support.switch-link-label')}
    />
  );
};

export default SupportLink;
