'use client';

import { CheckEmailResponse } from '@/shared/apiResponses/auth';
import { SESSION_STORAGE_KEYS } from '@/shared/constants';
import { buildPathWithQueryParams } from '@/shared/urlHelper';
import {
  Action,
  Dependencies,
  Effect,
  Reducer,
} from '@bendingspoons/web-composable-architecture';

import { checkEmail } from '@/api/auth';
import { appContextEmail } from '@/components/TCA/dependencies/AppContextEmail';
import { appRouter } from '@/components/TCA/dependencies/AppRouter';
import { hCaptcha } from '@/components/TCA/dependencies/HCaptcha';
import { pico } from '@/components/TCA/dependencies/Pico';
import { sessionStorage } from '@/components/TCA/dependencies/SessionStorage';
import { getBestLocale } from '@/i18n';
import {
  loginApplePressedAction,
  loginEmailDisplayedAction,
  loginEmailPressedAction,
  loginGooglePressedAction,
} from '@/pico/picoUserActions';
import { RouterEnhanced } from '@/utils/useRouterEnhanced';

export class LoginEmailFormState {
  isLoading: boolean;
  email: string;
  captchaToken: string | null;
  errorMessage: string | null;
  captchaMode: 'visible' | 'invisible';
  isDone: boolean = false;

  constructor() {
    this.isLoading = false;
    this.errorMessage = null;
    this.email = '';
    this.captchaToken = null;
    this.captchaMode = 'invisible';
  }

  get isVisibleCaptcha(): boolean {
    return this.captchaMode === 'visible';
  }

  get isCaptchaFilled(): boolean {
    const { captchaMode, captchaToken } = this;
    return (
      captchaMode === 'invisible' ||
      (captchaMode === 'visible' && !!captchaToken)
    );
  }

  get canSubmitForm(): boolean {
    return this.isCaptchaFilled && !!this.email && !this.isLoading;
  }
}

export type LoginEmailFormActions =
  | Action<'onComponentDidMount'>
  | Action<'didTriggerSocialLogin', { provider: 'google' | 'apple' }>
  | Action<'didChangeEmailInput', { email: string }>
  | Action<'didSubmitLoginEmailForm'>
  | Action<'onSetCaptchaToken', { token: string | null }>
  | Action<'handleSubmitExecuteCaptchaError'>
  | Action<
      'handleLoginEmailSubmitResponse',
      {
        response: CheckEmailResponse;
      }
    >;

export class LoginEmailFormReducer extends Reducer<
  LoginEmailFormState,
  LoginEmailFormActions
> {
  run(
    state: LoginEmailFormState,
    action: LoginEmailFormActions
  ): Effect<LoginEmailFormActions> {
    const dependencies = Dependencies.current.getAll({
      appContextEmail,
      appRouter,
      hCaptcha,
      pico,
      sessionStorage,
    });

    switch (action.type) {
      case 'onComponentDidMount': {
        return Effect.fireAndForget(() => {
          // clean any email / username from session storage and appContext
          dependencies.sessionStorage.removeItem(SESSION_STORAGE_KEYS.EMAIL);
          dependencies.appContextEmail.setEmail('');

          dependencies.pico.trackUserAction(loginEmailDisplayedAction());
        });
      }
      case 'didTriggerSocialLogin': {
        const { provider } = action.payload;

        // prevent the user from submitting the email form
        state.isLoading = true;

        return Effect.fireAndForget(() => {
          const url = buildPathWithQueryParams('/auth/switchProvider', {
            provider,
          });
          if (provider === 'google') {
            dependencies.pico.trackUserAction(loginGooglePressedAction());
          }
          if (provider === 'apple') {
            dependencies.pico.trackUserAction(loginApplePressedAction());
          }
          dependencies.appRouter.push(url);
        });
      }
      case 'didChangeEmailInput': {
        const { email } = action.payload;
        state.email = email;
        return Effect.none();
      }
      case 'onSetCaptchaToken': {
        const { token } = action.payload;
        state.captchaToken = token;
        // token expired need to reset
        if (token === null) {
          return Effect.fireAndForget(() => {
            dependencies.hCaptcha.resetCaptcha();
          });
        }
        return Effect.none();
      }
      case 'handleSubmitExecuteCaptchaError': {
        state.isLoading = false; // remove loading operation (form has to be resubmitted)
        state.captchaToken = null;

        state.captchaMode = 'visible'; // switch to visible mode
        state.errorMessage = 'error.captcha-failed'; // handle it as a captcha failure

        return Effect.fireAndForget(() => {
          dependencies.hCaptcha.resetCaptcha();
        });
      }
      case 'didSubmitLoginEmailForm': {
        // none effect if can't submit. Avoid captcha token error page (captcha is visible they cannot resolve it)
        if (!state.canSubmitForm) {
          return Effect.none();
        }
        // set loading state (a request should be sent)
        state.isLoading = true;
        state.errorMessage = null;
        // we should always call execute captcha.
        // By construction this method is async and will resolve with a token in the store (unless an error is triggered).
        return Effect.run(async (send) => {
          const hCaptchaResponse = await dependencies.hCaptcha.executeCaptcha();
          if (!hCaptchaResponse) {
            return send.next({ type: 'handleSubmitExecuteCaptchaError' });
          }
          try {
            const language = getBestLocale();
            const body = await checkEmail(
              state.email,
              hCaptchaResponse,
              language,
              undefined
            );
            send.next({
              type: 'handleLoginEmailSubmitResponse',
              payload: {
                response: body,
              },
            });
          } catch (e) {
            this.navigateToErrorPage(dependencies.appRouter);
            send.error(e);
          }
        });
      }
      case 'handleLoginEmailSubmitResponse': {
        const { response } = action.payload;

        state.isLoading = false;
        state.captchaToken = null;

        switch (response.status) {
          case 'deactivatedAccount': {
            return Effect.fireAndForget(() => {
              dependencies.sessionStorage.setItem(
                SESSION_STORAGE_KEYS.EMAIL,
                state.email
              );
              dependencies.appContextEmail.setEmail(state.email);
              const url = buildPathWithQueryParams('/status', {
                message: 'reactivate-account-email-sent',
              });
              dependencies.appRouter.push(url);
            });
          }
          case 'emailNotFound': {
            state.errorMessage = 'error.email-not-found';

            return Effect.fireAndForget(() => {
              dependencies.hCaptcha.resetCaptcha();
            });
          }
          case 'success': {
            state.isDone = true;
            const { isCodeLoginEnabled } = response;

            return Effect.fireAndForget(() => {
              dependencies.sessionStorage.setItem(
                SESSION_STORAGE_KEYS.EMAIL,
                state.email
              );
              dependencies.appContextEmail.setEmail(state.email);
              const redirectUrl = getSuccessStepRedirect(isCodeLoginEnabled);

              dependencies.pico.trackUserAction(loginEmailPressedAction());
              dependencies.appRouter.push(redirectUrl);
            });
          }
          case 'samlAuthorize': {
            const { samlRedirectUrl } = response;
            return Effect.fireAndForget(() => {
              dependencies.sessionStorage.setItem(
                SESSION_STORAGE_KEYS.SAML_REDIRECT_URL,
                samlRedirectUrl
              );
              dependencies.appRouter.push('/saml-redirect');
            });
          }
          case 'permanentlyDeactivatedAccount': {
            state.captchaMode = 'visible';
            state.errorMessage = 'error.permanently-deactivated-account';

            return Effect.fireAndForget(() => {
              dependencies.hCaptcha.resetCaptcha();
            });
          }
          case 'captchaError': {
            state.captchaMode = 'visible';
            state.errorMessage = 'error.captcha-failed';

            return Effect.fireAndForget(() => {
              dependencies.hCaptcha.resetCaptcha();
            });
          }
          case 'internalError':
            return Effect.fireAndForget(() => {
              this.navigateToErrorPage(
                dependencies.appRouter,
                response.supportCode
              );
            });
          default:
            return Effect.fireAndForget(() => {
              this.navigateToErrorPage(dependencies.appRouter);
            });
        }
      }
    }
  }

  navigateToErrorPage(appRouter: RouterEnhanced, errorId?: string) {
    const queryParams: Record<string, string> = {};
    if (errorId) {
      queryParams.errorId = errorId;
    }
    const url = buildPathWithQueryParams('/error', queryParams);
    appRouter.push(url);
  }
}

function getSuccessStepRedirect(isCodeLoginEnabled: boolean) {
  return isCodeLoginEnabled ? '/login-with-code' : 'login-with-password';
}
