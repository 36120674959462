'use client';

import {
  Store,
  useAsDependency,
  useStoreRef,
  useViewStore,
} from '@bendingspoons/web-composable-architecture';
import classNames from 'classnames';
import React, { FormEvent, useCallback, useEffect } from 'react';
import { useTranslations } from 'use-intl';

import Captcha, { CaptchaMethods } from '@/components/core/Captcha';
import LoadingWrapper from '@/components/core/LoaderContainer';
import { appContextEmail } from '@/components/TCA/dependencies/AppContextEmail';
import { appRouter } from '@/components/TCA/dependencies/AppRouter';
import { hCaptcha } from '@/components/TCA/dependencies/HCaptcha';
import { useRefAsDependency } from '@/components/TCA/TCA+Utils';
import BottomLink from '@/components/ui/BottomLink';
import Button from '@/components/ui/Button';
import InputEmail from '@/components/ui/InputEmail';
import SocialAuthButtons from '@/components/ui/SocialAuthButtons';
import SupportLink from '@/components/ui/SupportLink';
import { useAppContext } from '@/configuration/AppContext';
import { useRouterEnhanced } from '@/utils/useRouterEnhanced';

import {
  LoginEmailFormReducer,
  LoginEmailFormState,
} from './LoginEmailForm+Logic';

type LoginEmailFormProps = {
  className?: string;
};

const LoginEmailForm = ({ className }: LoginEmailFormProps) => {
  const appContext = useAppContext();
  const router = useRouterEnhanced();

  const store = useStoreRef(
    () => new Store(new LoginEmailFormState(), new LoginEmailFormReducer())
  );
  const [state, send] = useViewStore(store);

  // appContextEmail dependency
  useAsDependency(
    appContextEmail,
    () => ({
      email: appContext.email,
      setEmail: appContext.setEmail,
    }),
    store,
    [appContext]
  );
  // router dependency
  useAsDependency(appRouter, () => router, store, [router]);

  // hcaptcha dependency ref
  const captchaRef = useRefAsDependency<CaptchaMethods>(store, hCaptcha);

  const t = useTranslations('form');

  const handleContinue = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      send({
        type: 'didSubmitLoginEmailForm',
      });
    },
    [send]
  );

  const handleEmailChange = useCallback(
    (value: string) => {
      send({ type: 'didChangeEmailInput', payload: { email: value } });
    },
    [send]
  );

  const handleSocialAuthClick = useCallback(
    (authProvider: 'google' | 'apple') => {
      send({
        type: 'didTriggerSocialLogin',
        payload: { provider: authProvider },
      });
    },
    [send]
  );

  const handleSetToken = useCallback(
    (token: string | null) => {
      send({ type: 'onSetCaptchaToken', payload: { token } });
    },
    [send]
  );

  useEffect(() => {
    send('onComponentDidMount');
  }, [send]);

  return (
    <LoadingWrapper className={className} isLoading={state.isLoading}>
      <form onSubmit={handleContinue}>
        <div
          className={classNames('flex flex-col', {
            'pb-4': state.isVisibleCaptcha,
          })}
        >
          <InputEmail
            placeholder={t('email-username-input-label')}
            errorMessage={state.errorMessage ? t(state.errorMessage) : null}
            onChange={handleEmailChange}
            value={state.email}
          />
        </div>

        <Captcha
          mode={state.captchaMode}
          ref={captchaRef}
          setToken={handleSetToken}
        />

        <Button
          className="mt-4"
          disabled={!state.canSubmitForm}
          buttonStyle="primary"
          buttonType="submit"
          isLoading={state.isLoading}
        >
          {t('button-label')}
        </Button>
      </form>

      <SocialAuthButtons
        onGoogleClick={() => handleSocialAuthClick('google')}
        onAppleClick={() => handleSocialAuthClick('apple')}
      />
      <div>
        <BottomLink
          linkUrl="/registration"
          linkCopy={t('login.switch-label')}
          spanCopy={t('login.switch-link-label')}
        />
        <SupportLink />
      </div>
    </LoadingWrapper>
  );
};

export default LoginEmailForm;
